import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Img from 'gatsby-image';

const Footer = () => {

    const data = useStaticQuery(graphql`
    query {
        facebook: file(relativePath: {eq: "facebook-icon.png"}) {
            childImageSharp {
                fluid(quality: 80) {
                    srcSet
                }
            }
         }

         logo: file(relativePath: {eq: "logo.png"}) {
            childImageSharp {
                fluid(quality: 95) {
                    srcSet
                }
            }
         }
    }
  `)

   return (
      <footer>
          <div className="container">
              <div className="col">
                  <a href="https://silesiaterm.pl/" target="_blank">
                      <Img fluid={ data.logo.childImageSharp.fluid } className="logo" />
                  </a>
              </div>
              <div className="col">
                  <p>
                    <b>Silesia Term SP. Z O.O.</b> <br/>  
                    Nowy Dwór 6 48-130 Kietrz, Polska  <br/>
                    NIP: 748 158 17 05 <br/>
                    REGON: 161591855
                  </p>
              </div>
              <div className="col">
                <p>
                    <a href="tel: +48 665 920 944">tel.: +48 665 920 944</a> <br/>  
                    <a href="mailto: handlowy@silesia-term.pl">email: handlowy@silesia-term.pl</a>
                </p>
              </div>
              <div className="col">
                <a href="https://www.facebook.com/SilesiaTerm/" target="_blank">
                    <Img fluid={ data.facebook.childImageSharp.fluid } className="facebook" />
                </a>
              </div>
          </div>
          <div className="container copyrights">
              <div className="copy">
                ©{ new Date().getFullYear() } Silesia Term
              </div>
              <div className="author">
                Realizacja: <a href="http://norddigital.com/" target="_blank">Nord Digital</a>
              </div>
          </div>
      </footer>
  )
}
 

export default Footer

