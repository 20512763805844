import { useStaticQuery, graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import Img from 'gatsby-image';
import AnchorLink from 'react-anchor-link-smooth-scroll'

const MainBanner = () => {
  let [ load, setLoad ] = useState(false);

  useEffect(() => {
    if ( !load ) {
      setLoad(true);
    }
  }, [load]);

    const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: {eq: "banner.jpg"}) {
        childImageSharp {
          fixed(quality: 80, width: 1919) {
            src
          }
        }
      }
      arrowBottom: file(relativePath: {eq: "arrow-bottom.png"}) {
        childImageSharp {
          fixed(quality: 95, width: 12) {
            src
          }
        }
      }
    }
  `)

   return (
      <section className="main-banner">
        <div className="wrapper">
          <Img fixed={ data.banner.childImageSharp.fixed } className="banner" alt="Silesia Term - Pompy Ciepła, Chillery" />
          <div className={"right-overlay" + (load ? '' : ' hidden') }>
            <h1>Chillery od polskiego producenta</h1>
            <p>Agregaty SILESIA-TERM zostały zaprojektowane i wykonana zgodnie z aktualnymi zasadami techniki i obowiązującymi przepisami bezpieczeństwa</p>
            <AnchorLink href="#o-produkcie" className="button">
              Zobacz o produkcie
            </AnchorLink>
          </div>
          <div className="arrow-bottom">
          <AnchorLink href="#o-nas" className="arrow-link">
            <p>Zjedź niżej</p> 
            <div className="arrow-wrap">
                <img src={ data.arrowBottom.childImageSharp.fixed.src } className="arrow" alt="Oferta Silesia term" />
            </div>
            </AnchorLink>

          </div>
        </div>
      </section>
  )
}
 

export default MainBanner

