import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import './../scss/main.scss'
import MainBanner from './../components/main-banner/MainBanner'
import AboutUs from "../components/about-us/AboutUs"
import SliderGallery from "../components/slider/Slider"
import AboutProduct from "../components/about-product/AboutProduct"
import FullWidthImage from "../components/full-width-image/FullWidthImage"
import Contact from "../components/contact/Contact"
import Footer from "../components/footer/Footer"

const IndexPage = () => (
  <Layout>
    <SEO title="Strona Główna" />
    <MainBanner />
    <AboutUs />
    <SliderGallery />
    <AboutProduct />
    <FullWidthImage />
    <Contact />
    <Footer />
  </Layout>
)

export default IndexPage
