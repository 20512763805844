import { useStaticQuery, graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import Img from 'gatsby-image';

const FullWidthImage = () => {

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: {eq: "contact-sep.jpg"}) {
        childImageSharp {
          fluid(quality: 80) {
            srcSet
          }
        }
      }
    }
  `)

   return (
      <section className="full-width-image">
        
        <Img fluid={ data.image.childImageSharp.fluid } className="image-full" alt="Silesia term - Pompy ciepła, chillery"/>

      </section>
  )
}
 

export default FullWidthImage

