import { useStaticQuery, graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import Img from 'gatsby-image';

const AboutUs = () => {

    const data = useStaticQuery(graphql`
    query {
      decor: file(relativePath: {eq: "top-decor.png"}) {
        childImageSharp {
          fluid(quality: 80) {
            srcSet
          }
        }
      }
    }
  `)

   return (
      <section className="about-us" id="o-nas">
          <Img fluid={ data.decor.childImageSharp.fluid } className="decor-top" />
          <div className="container">
            <div className="col">
              <div className="circles">
                <div className="circle">
                  <p>
                    <span>6</span>
                    lat na rynku
                  </p>
                </div>
                <div className="circle middle">
                  <p>
                    Polski<br/>
                    producent
                  </p>
                </div>
                <div className="circle">
                  <p>
                    <span>14</span>
                    lat <br/>  doświadczenia
                  </p>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="text-wrap">
                <h2>O nas</h2>
                <p>
                  Silesia Term to polski producent pomp ciepła, który działa na rynku od 6 lat, lecz z ponad 14 letnim doświadczeniem. To jeden z niewielu producentów polskich pomp ciepła powietrznych i gruntowych. 
                  <br/><br/>
                  Silesia Term to nie tylko producent pomp ciepła, ale także agregatów wody lodowej i specjalistycznych urządzeń chłodniczych dostosowanych i projektowanych na specjalne potrzeby wymagających klientów. 
                  <br/><br/>
                  Naszą firmę wyróżnia błyskawiczna pomoc instalatorów szkolonych w zakresie montażu i instalacji pomp ciepła. Ogrom doświadczenia pozwolił stworzyć nam produkty wysokiej jakości  w przystępnej cenie, mogące służyć naszym klientom przez lata. Każdy nasz produkt objęty jest 5 letnią gwarancją. Chętnie służymy pomocą , podpowiemy w wyborze odpowiedniego urządzenia.
                </p>
              </div>
            </div>
          </div>
      </section>
  )
}
 

export default AboutUs

