import { useStaticQuery, graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import Img from 'gatsby-image';
import Slider from "react-slick";

const SliderGallery = () => {

    const data = useStaticQuery(graphql`
    query {
        sliderDecor: file(relativePath: {eq: "slider-decor.png"}) {
          childImageSharp {
            fluid(quality: 80) {
              srcSet
            }
          }
        }
        slide1: file(relativePath: {eq: "chiller-1.jpg"}) {
          childImageSharp {
            fluid(quality: 80) {
              src
            }
          }
        }
        slide2: file(relativePath: {eq: "chiller-2.jpg"}) {
          childImageSharp {
            fluid(quality: 80) {
              src
            }
          }
        }
        slide3: file(relativePath: {eq: "chiller-3.jpg"}) {
          childImageSharp {
            fluid(quality: 80) {
              src
            }
          }
        }
        slide4: file(relativePath: {eq: "chiller-4.jpg"}) {
          childImageSharp {
            fluid(quality: 80) {
              src
            }
          }
        }
        slide5: file(relativePath: {eq: "chiller-5.jpg"}) {
          childImageSharp {
            fluid(quality: 80) {
              src
            }
          }
        }
        slide6: file(relativePath: {eq: "chiller-6.jpg"}) {
          childImageSharp {
            fluid(quality: 80) {
              src
            }
          }
        }
        slide7: file(relativePath: {eq: "chiller-7.jpg"}) {
          childImageSharp {
            fluid(quality: 80) {
              src
            }
          }
        }
        slide8: file(relativePath: {eq: "chiller-8.jpg"}) {
          childImageSharp {
            fluid(quality: 80) {
              src
            }
          }
        }
      }
    `)

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1155,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 863,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 595,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

   return (
      <section className="slider-section">
          <div className="decor">
            <Img fluid={ data.sliderDecor.childImageSharp.fluid } className="slider-decor" />
          </div>

          <div className="container">
            <Slider {...settings}>
              <div>
                <Img fluid={ data.slide1.childImageSharp.fluid } className="slider-img" />
              </div>
              <div>
                <Img fluid={ data.slide2.childImageSharp.fluid } className="slider-img" />
              </div>
              <div>
                <Img fluid={ data.slide3.childImageSharp.fluid } className="slider-img" />
              </div>
              <div>
                <Img fluid={ data.slide4.childImageSharp.fluid } className="slider-img" />
              </div>
              <div>
                <Img fluid={ data.slide5.childImageSharp.fluid } className="slider-img" />
              </div>
              <div>
                <Img fluid={ data.slide6.childImageSharp.fluid } className="slider-img" />
              </div>
              <div>
                <Img fluid={ data.slide7.childImageSharp.fluid } className="slider-img" />
              </div>
              <div>
                <Img fluid={ data.slide8.childImageSharp.fluid } className="slider-img" />
              </div>
            </Slider>
          </div>
      </section>
  )
}
 

export default SliderGallery

