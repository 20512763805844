import { useStaticQuery, graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import Img from 'gatsby-image';

const AboutProduct = () => {

    const data = useStaticQuery(graphql`
    query {
      product: file(relativePath: {eq: "about-product.jpg"}) {
        childImageSharp {
          fluid(quality: 80) {
            srcSet
          }
        }
      }
    }
  `)

   return (
      <section className="about-product" id="o-produkcie">
        
          <div className="container">
            <div className="col">
              <div className="text-wrap">
                <h2>O Produkcie</h2>
                <p>
                  Schładzacze cieczy, inaczej chillery, agregaty wody lodowej (ziębniczej) czy agregaty chłodnicze, są urządzeniami chłodniczymi i znajdują coraz szersze zastosowanie w szeregu gałęzi przemysłu i produkcji. Schładzacze cieczy są niezbędnym elementem w zamkniętych obiegach chłodzących maszyn produkcyjnych, ale również różnej maści systemów klimatyzacyjnych. Schładzacze pozwalają utrzymać odpowiednią temperaturę cieczy  chłodząco-smarujących w obrabiarkach, elektrodrążarkach, sprzętach spawalniczych, które polegają na sprawnej wymianie ciepła. 
                  <br/><br/>
                  Nasze urządzenia oznaczone są znakiem CE co jest potwierdzeniem, że firma SILESIA-TERM zadbała o zgodność produktu ze wszystkimi obowiązującymi przepisami prawa w tym zakresie, a w szczególności przepisów dyrektyw UE. Znak CE jest wymagany dla większości produktów sprzedawanych w UE, bez względu na miejsce ich wytwarzania.
                  <br/><br/>
                  Chillery naszej firmy oferujemy w atrakcyjnych cenach, wykonujemy różne konfiguracje urządzń, a także dajemy możliwość wykonania chillera na zamówienie. Chillery w mocach od 5 kw do 500 kw
                </p>
              </div>
            </div>
            <div className="col">
              <div className="img-wrapper">
                <Img fluid={ data.product.childImageSharp.fluid } className="product-img" alt="Silesia term - Pompy ciepła, chillery"/>
              </div>
            </div>
          </div>
      </section>
  )
}
 

export default AboutProduct

