import { useStaticQuery, graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import Img from 'gatsby-image';

const Contact = () => {
    let [ checkbox, setCheckbox ] = useState(false);
    let [ name, setName ] = useState('');
    let [ surname, setSurname ] = useState('');
    let [ subject, setSubject ] = useState('');
    let [ message, setMessage ] = useState('');
    let [ error, setError ] = useState('');
    let [ btnText, setBtnText ] = useState('Wyślij');

    const data = useStaticQuery(graphql`
    query {
        decor: file(relativePath: {eq: "top-decor.png"}) {
            childImageSharp {
            fluid(quality: 80) {
                srcSet
            }
            }
         }
        }
  `)

  let send = () => {
    const data = `message=${message}&name=${name}&email=${surname}&subject=${subject}`
    const xhr = new XMLHttpRequest()
    xhr.open("POST", `https://mail.chillery.eu/`, true)
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
    xhr.send(data)
  }

  let submitForm = async() => {
      if (!checkbox || name.length === 0 || surname.length === 0 || subject.length === 0 ||  message.length === 0 ) {
        setError('Uzupełnij wszystkie dane formularza')
      } else {
          await send()
          setError('');
          setName('');
          setSurname('');
          setSubject('');
          setMessage('');
          setBtnText('Wysłano')
          setCheckbox(false)
      }
  }

   return (
      <section className="contact" id="formularz-zapytania">
        <Img fluid={ data.decor.childImageSharp.fluid } className="decor-top" />
        <div className="container">
            <div className="text-wrap">
                <h2>Napisz do nas</h2>
                <p>Chciałbyś dowiedzieć się więcej o naszej ofercie? A może chciałbyś złozyc zamówienie? Skontaktuj się z nami przez formularz kontaktowy!</p>
            </div>
            <div className="form-wrap">
                <div className="col">
                    <div className="inputs">
                        <input type="text" placeholder="Imię i nazwisko" value={ name } onChange={ (e) => setName(e.target.value) } />
                        <input type="text" placeholder="E-mail" value={ surname } onChange={ (e) => setSurname(e.target.value) } />
                        <input type="text" placeholder="Temat" value={ subject } onChange={ (e) => setSubject(e.target.value) } />
                    </div>
                    <div className="checkbox-wrap">
                        <div className="checkbox" onClick={ () => setCheckbox(!checkbox) } >
                            { checkbox && 
                                <div className="checked" />
                            }
                        </div>
                        <p onClick={ () => setCheckbox(!checkbox) }>
                            <span>TAK</span> , wyrażam zgodę na przetwarzanie danych osobowych w celu przygotowania i przekazania odpowiedzi na zadane pytanie/a oraz ich archiwizacji 
                        </p>
                    </div>
                </div>
                <div className="col">
                    <textarea rows="22" placeholder="Tutaj wpisz treść wiadomości" value={ message } onChange={ (e) => setMessage(e.target.value) } />
                </div>
            </div>
            <div className="button-wrap">
                <a className="button" onClick={ () => submitForm() }>{ btnText }</a>
            </div>
            { error.length > 0 && 
                <div className="error-wrap">
                    <p className="error">
                        { error }
                    </p>
                </div>
            }
        </div>

      </section>
  )
}
 

export default Contact

